<template>
  <main class="home">
    <div class="hero is-primary">
      <div class="hero-body">
        <h2 class="subtitle has-text-centered">Viewing Listing</h2>
        <h1 class="title has-text-centered">{{ routeID }}</h1>
      </div>
    </div>
    <br /><br /><br />
    <div class="container">
      <div class="columns is-multiline">
        <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
        <div v-if="propertyData" class="column is-12">
          <div class="columns listing-container">
            <div class="column is-6 carousel">
              <h2 class="subtitle">Asking Price</h2>
              <hr class="thin" />
              <h1 class="title">
                {{ formatPrice(propertyData.data.list_price) }}
              </h1>
              <br />
              <h2 class="subtitle">Address</h2>
              <hr class="thin" />
              <p>
                {{ propertyData.data.street_1 }}
                {{ propertyData.data.street_2 }}, {{ propertyData.data.city }},
                {{ propertyData.data.state }}
                {{ propertyData.data.zip }}
              </p>
              <br />
              <a :href="gMapsURL" target="_blank" rel="noopener">
                View in Google Maps
                <i
                  class="fa-solid fa-external-link"
                  style="margin-left: 0.5rem"
                ></i>
              </a>
              <br />
              <br />
              <br />
              <h2 class="subtitle">Quick Details</h2>
              <hr class="thin" />
              <div class="columns">
                <div class="column is-12">
                  <div class="columns is-mobile quick-details">
                    <div class="column is-6">
                      <p class="text"><strong>Type</strong></p>
                      <p class="text"><strong>Subtype</strong></p>
                      <p class="text"><strong>Lot Size</strong></p>
                      <p class="text"><strong>Sqft</strong></p>
                      <p class="text"><strong>Price/Sq Ft</strong></p>
                      <p class="text"><strong>Zoning</strong></p>
                      <p class="text"><strong>NOI</strong></p>
                      <p class="text"><strong>Opportunity Zone</strong></p>
                    </div>
                    <div class="column is-6" style="text-align: right">
                      <p class="text">
                        {{ propertyData.data.property_type || "-" }}
                      </p>
                      <p class="text">
                        {{ propertyData.data.property_subtype || "-" }}
                      </p>
                      <p class="text">
                        {{ propertyData.data.lot_size || "-" }} acres
                      </p>
                      <p class="text">
                        {{
                          propertyData.data.sqft.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                          })
                        }}
                      </p>
                      <p class="text">{{ ppsqft }}</p>
                      <p class="text">{{ propertyData.data.zoning || "-" }}</p>
                      <p class="text">
                        {{ formatPrice(propertyData.data.noi) || "-" }}
                      </p>
                      <p class="text">
                        {{ propertyData.data.opportunity_zone ? "Yes" : "No" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h2 class="subtitle">Property Details</h2>
              <hr class="thin" />
              <div class="">
                <prismic-rich-text
                  :field="propertyData.data.property_details"
                  :htmlSerializer="htmlSerializer"
                  class="content"
                />
              </div>
              <br />
            </div>
            <div class="column is-6 carousel">
              <h2 class="subtitle">Photos</h2>
              <hr class="thin" />
              <VueSlickCarousel v-bind="sliderConfig">
                <div
                  v-for="(img, index) in propertyData.data.images"
                  :key="index"
                  class="listing-image"
                >
                  <img :src="img.image.url" height="200px" alt="" />
                </div>
              </VueSlickCarousel>
              <br />
              <br />
              <h2 class="subtitle">Map</h2>
              <hr class="thin" />
              <div class="map">
                <MglMap
                  v-if="coords"
                  :access-token="accessToken"
                  :map-style="mapStyle"
                  :center="coords"
                  :zoom="12.5"
                  container="map-container"
                >
                  <MglMarker :coordinates="coords" color="#d8af64" />
                </MglMap>
              </div>
              <br />
              <br />
              <h2 class="subtitle">Links</h2>
              <hr class="thin" />
              <div
                v-for="(link, index) in propertyData.data.links"
                :key="index"
                class="links"
              >
                <a :href="link.url" target="_blank" rel="noopener">
                  <i
                    class="fas fa-external-link"
                    style="margin-right: 0.5rem"
                  ></i>
                  {{ link.link_title }}
                </a>
              </div>
              <br />
              <br />
              <h2 class="subtitle">Attachments</h2>
              <hr class="thin" />
              <div
                v-for="(item, index) in propertyData.data.attachments"
                :key="index"
                class="attachements"
              >
                <a :href="item.document.url" target="_blank" rel="noopener">
                  <i class="fal fa-file" style="margin-right: 0.5rem"></i>
                  {{ item.attachment_title }}
                </a>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <!-- Any other Bulma elements you want -->
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";
export default {
  name: "Browse",
  data: () => {
    return {
      propertyData: undefined,
      inventoryCoordinates: undefined,
      modal: false,
      accessToken:
        "pk.eyJ1IjoibXR3aGNyZSIsImEiOiJja3BnMDcwcnIwYjFsMnVvOTMxM2JhdmJjIn0.yKkEMUKcPRyti3ED3mOQUg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mtwhcre/ckpotrod00aef17s4x08mzua5", // your map style
      center: [-83.37736141151493, 42.48706224043698],
      sliderConfig: {
        dots: true,
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  components: {
    VueSlickCarousel,
    MglMap,
    MglMarker,
  },
  created() {
    this.mapbox = Mapbox;
  },
  computed: {
    isLoading: function () {
      return this.propertyData ? false : true;
    },
    routeID: function () {
      return this.$route.params.id;
    },
    coords: function () {
      const arr = [];
      arr.push(
        this.propertyData.data.geopoint.longitude,
        this.propertyData.data.geopoint.latitude
      );
      console.log(arr);
      return arr;
    },
    gMapsURL: function () {
      return `https://maps.google.com/?q=${this.propertyData.data.street_1} ${this.propertyData.data.street_2} ${this.propertyData.data.city} ${this.propertyData.data.state} ${this.propertyData.data.zip}`;
    },
    ppsqft: function () {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(
        this.propertyData.data.list_price / this.propertyData.data.sqft
      );
    },
  },
  mounted() {
    this.loadPropertyData();
  },
  methods: {
    async loadPropertyData() {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.at(
            "my.listing.hillview_internal_id",
            this.routeID
          )
        )
        .then((res) => {
          if (res.results_size === 0) this.bail();
          else this.propertyData = res.results[0];
        });
    },
    buildGoogleMapsURL() {
      return `http://maps.google.com/?q=${this.propertyData.data.street_1} ${this.propertyData.data.street_2} ${this.propertyData.data.city} ${this.propertyData.data.state} ${this.propertyData.data.zip}`;
    },
    formatPrice(n) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(n);
    },
    bail() {
      this.$router.push("/inventory");
    },
    htmlSerializer(type, element, content, children) {
      if (type === "heading2" || type === "heading3" || type === "heading4") {
        return `<h6 style="color: #d8af64; font-family: "Playfair Display";">${children.join(
          ""
        )}</h6>`;
      }
      if (type === "strong") {
        return `<strong style="color: #d8af64; margin-left: .5rem">${children.join(
          ""
        )}</strong>`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-item,
.title {
  color: #d8af64 !important;
  font-family: "Playfair Display";
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
hr {
  background: hsl(39, 40%, 32%);
}
.map {
  height: 200px;
  width: 100%;
  border: 1px solid hsl(39, 40%, 32%);
  border-radius: 4px;
  overflow: hidden;
  .map-container {
    height: 300px;
    width: 100%;
  }
}
main {
  background: #1a1f36;
  min-height: 100vh;
  padding-bottom: 20rem;
  font-family: "Lato", sans-serif;
}
.text {
  color: white;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  font-family: "Lato", sans-serif;
}
.listing-container {
  color: ghostwhite;
  p,
  .content {
    color: rgb(218, 218, 224);
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
    .subtitle {
      font-size: 1.5rem;
      font-weight: 800;
      color: #d8af64;
      font-family: "Playfair Display";
    }
    strong {
      color: #d8af64 !important;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 800;
      color: #d8af64 !important;
      font-family: "Playfair Display";
    }
  }
  strong {
    color: #d8af64bd;
  }
  .title {
    color: ghostwhite !important;
  }
  .subtitle {
    font-size: 1.5rem;
    font-weight: 800;
    color: #d8af64;
    font-family: "Playfair Display";
  }
}
a {
  color: #d8af64;
}
.carousel {
  padding: 0rem 4rem;
}

@media only screen and (max-width: 600px) {
  .listing-container {
    p,
    .content {
      color: rgb(218, 218, 224);
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
    .carousel {
      padding: 0rem 2rem;
    }
  }
}
</style>
